import React, { useState } from "react";
import { CondominiumForm } from "./CondominiumForm";
import { useSnackbar, handleError } from "@condofy/next/hooks/useSnackbar";
import { Modal, ActionRow, ModalContent } from "@condofy/next/components";
import { track } from "@condofy/next/service/segment";
import { useUserContext } from "@condofy/next/store/user";

export const CondominiumCreateModal = () => {
  const {
    state,
    actions: {
      listCondominium,
      createCondominium,
      closeCreateCondominiumModal: close
    }
  } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [condominium, setCondominium] = useState({
    cnpj: "",
    name: "",
    type: { id: "1" },
    address: {
      street: "",
      number: "",
      district: "",
      city: "",
      state: "",
      zipCode: ""
    },
    code: "",
    plan: { id: "1" },
    invoiceEmail: "",
    paymentSubscriptionId: ""
  });

  const handleClose = () => {
    setCondominium(undefined);
    close();
  };

  const handleSuccess = () => {
    track(window.analytics, "Condominium Created", condominium);
    enqueueSnackbar(`Condomínio criado com sucesso`, { variant: "success" });
    handleClose();
    listCondominium();
  };

  const reset = () => setLoading(false);

  const handleSave = () => {
    setLoading(true);
    createCondominium({
      ...condominium,
      cnpj: condominium.cnpj.replace(/\D/g, ""),
      address: {
        ...condominium.address,
        zipCode: condominium.address.zipCode.replace(/\D/g, "")
      }
    })
      .then(handleSuccess)
      .catch(handleError(enqueueSnackbar))
      .finally(reset);
  };

  return (
    <Modal
      open={state.condominium.createModalOpen}
      onClose={handleClose}
      title="Novo Condomínio"
      footer={
        <ActionRow
          onCancel={handleClose}
          onSubmit={handleSave}
          loading={loading}
        />
      }
    >
      <ModalContent>
        <CondominiumForm
          condominium={condominium}
          editMode={false}
          onChange={setCondominium}
        />
      </ModalContent>
    </Modal>
  );
};
