import { Page, TabsPanel, TabsPanelItem } from "@condofy/components";
import { EditStatementItemCategoryModal } from "@condofy/next/containers/finance";
import React from "react";
import { FinancialStatementTab } from "./FinancialStatementTab";
import { OverviewTab } from "./OverviewTab";
import { InsuranceDialog } from "~/next/components/InsuranceDialog";

export const FinancesDashboardPage = () => (
  <Page>
    <TabsPanel>
      <TabsPanelItem label="Geral">
        <OverviewTab />
      </TabsPanelItem>
      <TabsPanelItem label="Extrato Financeiro">
        <FinancialStatementTab />
      </TabsPanelItem>
    </TabsPanel>
    <EditStatementItemCategoryModal />
    <InsuranceDialog />
  </Page>
);
