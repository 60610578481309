import { colors, FlexRowCentered } from "@condofy/components";
import { CondofyLogo } from "@condofy/next/components";
import { Spin as Hamburger } from "hamburger-react";
import { MainContext } from "Layout/Main";
import React, { useContext } from "react";
import styled from "styled-components";
import { UserMenu } from "./UserMenu";

const Navbar = styled.nav`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
`;

const Header = styled.header.withConfig({
  shouldForwardProp: (prop) => !["collapsed"].includes(prop)
})`
  background: ${colors.light100};
  box-shadow:
    0px 4px 15px rgba(0, 0, 0, 0.05),
    inset 0px -1px 0px #e7eaf0;
  padding: 0 24px;
  transition: width 0.7s ease;
  z-index: 98;
`;

export const TopNavbar = () => {
  const { sidebarCollapsed, toggleSidebar } = useContext(MainContext);

  return (
    <Header collapsed={Boolean(sidebarCollapsed).toString()}>
      <Navbar>
        <FlexRowCentered style={{ flexGrow: 1 }}>
          <CondofyLogo />
          <Hamburger
            size={24}
            color={colors.primary}
            toggled={sidebarCollapsed}
            toggle={toggleSidebar}
          />
          <div
            style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}
          ></div>
        </FlexRowCentered>
        <UserMenu />
      </Navbar>
    </Header>
  );
};
