import hmacSHA256 from "crypto-js/hmac-sha256";
import HexDigest from "crypto-js/enc-hex";

const intercomPrivateKey =
  process.env.REACT_APP_INTERCOM_SECRET ||
  process.env.NEXT_PUBLIC_INTERCOM_SECRET;

export const identify = (analytics, user, organization_id) => {
  if (!organization_id) {
    return;
  }
  const { id, name, email, isSuperAdmin, isAdmin, condominiums, phoneNumber } =
    user;

  const roles = condominiums.map((items) => {
    return items.roles;
  });

  function isManager(value) {
    return value === "2";
  }

  const userManager = isSuperAdmin
    ? [1]
    : roles[0].map((el) => el.id).filter(isManager);

  analytics.identify(id, {
    name,
    email,
    user_hash: HexDigest.stringify(hmacSHA256(email, intercomPrivateKey)),
    logins: user.loginCount,
    isSuperAdmin,
    isAdmin,
    organization_id,
    userType: userManager.length ? "Sindico" : "Morador",
    Phone: phoneNumber
  });
};

export const group = (analytics, currentCondominium, payload) => {
  analytics.group(currentCondominium.id, {
    condominium_id: currentCondominium.id,
    Chave: currentCondominium.code,
    name: currentCondominium.name,
    Plano: currentCondominium.plan.name,
    status: currentCondominium.isInactive ? "Inativo" : "Ativo",
    ...payload
  });
};

export const track = (analytics, eventName, payload) => {
  analytics.track(eventName, payload);
};

export const reset = (analytics) => {
  analytics.reset();
};
