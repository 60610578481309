import {
  Button,
  Form,
  FormRow,
  FormSection,
  FormSectionHeader,
  FormSectionTitle,
  MenuItem,
  TextField
} from "@condofy/components";
import {
  ActionRow,
  Modal,
  // ActionRow,
  ModalContent,
  ModalSection
} from "@condofy/next/components";
import { handleError, useSnackbar } from "@condofy/next/hooks/useSnackbar";
import { track } from "@condofy/next/service/segment";
import { createUnit, editUnit } from "@condofy/next/service/unit";
import { useUnitTypeContext } from "@condofy/next/store/unit-type";
import { useUserContext } from "@condofy/next/store/user";
import { toggleModal } from "Actions/modals";
import { DELETE_UNIT_MODAL } from "Const";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UnitUserList } from "../../unit";
// import { useLocalStorage } from '@uidotdev/usehooks'

export default ({ close, options }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentCondominium = options.condominium;
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState({
    number: "",
    block: "",
    observations: "",
    type: 0,
    ...options.unit
  });
  const [unitType, setUnitType] = useState(unit?.type?.id || 99);

  const {
    actions: { listUnit }
  } = useUserContext();

  const stableListUnits = useCallback(listUnit, []);

  const {
    actions: { listUnitTypes }
  } = useUnitTypeContext();

  const stableListUnitTypes = useCallback(listUnitTypes, []);

  useEffect(() => {
    stableListUnitTypes();
  }, [stableListUnitTypes]);

  const updateUnitValue = (event) => {
    const { name, value } = event.target;
    if (name === "typeId") {
      setUnitType(value);
      setUnit({ ...unit, type: { id: value } });
      return;
    }
    setUnit({ ...unit, [name]: value });
  };

  const finalHandler = () => {
    stableListUnits({
      PageNumber: options.page,
      generalSearch: options.search,
      currentCondominium,
      RecordsPerPage: 12,
      includeUsers: true
    });
    setLoading(false);
    close();
  };

  const updatedUnit = {
    ...unit,
    type: {
      ...unit.type,
      id: unitType
    }
  };

  const onSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    console.log(unit);
    if (unit.id) {
      editUnit({ currentCondominium, unit })
        .then(() => {
          track(window.analytics, "Condominium Unit Edited", {
            condominium_id: currentCondominium.id,
            ...updatedUnit
          });
          enqueueSnackbar("Unidade alterada com sucesso", {
            variant: "success"
          });
        })
        .catch(handleError(enqueueSnackbar))
        .finally(finalHandler);
    } else {
      createUnit({ currentCondominium, unit })
        .then(() => {
          track(window.analytics, "Condominium Unit Created", {
            condominium_id: currentCondominium.id,
            ...unit
          });
          enqueueSnackbar("Unidade criada com sucesso", { variant: "success" });
        })
        .catch(handleError(enqueueSnackbar))
        .finally(finalHandler);
    }
  };

  const onDelete = (unit) =>
    dispatch(
      toggleModal(DELETE_UNIT_MODAL, true, {
        id: unit.id,
        condoId: currentCondominium.id
      })
    );
  const title = unit.id
    ? `Condomínio ${currentCondominium.name} - Editar unidade`
    : `Condomínio ${currentCondominium.name} - Nova unidade`;

  return (
    <Modal
      title={title}
      open
      onClose={close}
      footer={
        <ActionRow loading={loading} onSubmit={onSubmit} onCancel={close} />
      }
    >
      <ModalContent>
        <Form>
          <FormSection>
            <FormRow>
              <TextField
                label="Bloco"
                name="block"
                value={unit.block}
                onChange={updateUnitValue}
              />
            </FormRow>
            <FormRow>
              <TextField
                label="Unidade"
                name="number"
                value={unit.number}
                onChange={updateUnitValue}
              />
            </FormRow>
            <FormRow>
              <TextField
                label="Observações"
                name="observations"
                value={unit.observations}
                onChange={updateUnitValue}
              />
            </FormRow>
            <FormRow sx={{ marginTop: "16px", width: "100%" }}>
              <TextField
                select
                label="Selecione o tipo de unidade"
                placeholder="Selecione o tipo de unidade"
                name="typeId"
                value={unitType}
                onChange={(e) => {
                  setUnitType(e.target.value);
                  updateUnitValue(e);
                }}
              >
                <MenuItem value={1}> Apartamento </MenuItem>
                <MenuItem value={2}> Casa </MenuItem>
                <MenuItem value={99}> Outro </MenuItem>
              </TextField>
            </FormRow>
          </FormSection>
        </Form>
        {Boolean(unit.id) && (
          <>
            {Boolean(unit.users?.length) && (
              <ModalSection border={1}>
                <FormSectionHeader>
                  <FormSectionTitle>Moradores</FormSectionTitle>
                </FormSectionHeader>
                <UnitUserList
                  users={unit.users}
                  unit={unit}
                  condominium={currentCondominium}
                />
              </ModalSection>
            )}
            <ModalSection border={1}>
              <Button
                variant="text"
                color="danger"
                onClick={() => onDelete(unit)}
              >
                Excluir Unidade
              </Button>
            </ModalSection>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
