// import { fetchWithProxy } from "~/lib/util/fetch";
import { REDIRECT_TO_APP, ENVIRONMENT } from "../vendor/@condofy/env";
import { condofyProtected } from "./instance";
import { getToken } from "@condofy/next/vendor/@condofy/token";

const BemteviApiUnitTypeMap = {
  1: "APARTMENT",
  2: "HOUSE"
};

export const initBemteviCheckoutNew = async () => {
  const token = getToken();

  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/bemtevi/generate-checkout-insurance-url`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

  const responseData = await response.json();

  if (!responseData?.data?.checkoutUrl) {
    throw new Error("malformed response");
  }

  window.open(responseData?.data?.checkoutUrl, "_blank");
};

export const initBemteviCheckout = async ({ user, unit }) => {
  const {
    socialSecurityNumber: cpf,
    name,
    gender,
    birthdate,
    email,
    currentCondominium: { address },
    phoneNumber
  } = user;
  const { street, district: neighborhood, city, state, zipCode: cep } = address;
  const {
    number,
    type: { id: unitTypeId }
  } = unit;

  const finalGender = ["M", "F"].includes(gender) ? gender : "M";

  const _formatPhoneNumber = (phoneNumber) => {
    let phone =
      phoneNumber?.toString()?.length === 12
        ? phoneNumber?.toString()
        : "5561981737725";
    if ("999999999999" === phone) {
      phone = "5561981737725";
    }
    return phone;
  };

  const _formatDOB = (birthdate) => {
    let dob = typeof birthdate === "string" ? birthdate : "1985-05-05";
    return dob.split("T")[0];
  };

  const _getAssetComplementType = () => {
    switch (unit.type?.id) {
      case "1":
        return "Apto.";
      case "2":
        return "Casa";
      default:
        return "";
    }
  };

  const _formatBlockInfo = () => {
    const blockInfo = unit.block ? `-${unit.block}` : "";
    return `${unit.number}${blockInfo}`;
  };

  const complement = [_getAssetComplementType(), _formatBlockInfo()].join(" ");

  const data = {
    callbackUrl: REDIRECT_TO_APP[ENVIRONMENT],
    insured: {
      cpf,
      name,
      chosenName: name,
      gender: finalGender, // 'M' | 'F'
      birthdate: _formatDOB(birthdate),
      maritalStatus: "SINGLE", //'SINGLE' | 'MARRIED' | 'DIVORCED' | 'WIDOWED'
      nationality: "BR",
      residency: "BR",
      politicalExposure: {
        status: "NONE"
      },
      contact: {
        email,
        phoneNumber: _formatPhoneNumber(phoneNumber)
      }
    },
    asset: {
      type: BemteviApiUnitTypeMap[unitTypeId],
      location: {
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
        cep
      }
    }
  };

  const body = JSON.stringify(data);

  const URL = `${process.env.REACT_APP_BEMTEVI_API_BASE_URL}/homeowners/policies/${process.env.REACT_APP_POLICY_ID}/checkout/sessions`;

  const params = {
    method: "POST",
    body,
    headers: {
      "x-API-key": process.env.REACT_APP_BEMTEVI_API_KEY,
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };

  try {
    const response = await fetch(URL, params);

    /*     const response = await (
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? fetch
        : fetchWithProxy
    )(URL, params); */

    const responseData = await response.json();

    if (!responseData.checkoutUrl) {
      throw new Error("malformed response");
    }

    window.open(responseData.checkoutUrl, "_blank");
  } catch (error) {
    console.log({ error });
  }
};

export const restrictedUnitListPromise = ({ currentCondominium }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/scheduling-restriction/unit`
  );

export const fetchRestrictedUnitList = ({
  currentCondominium,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  restrictedUnitListPromise({ currentCondominium })
    .then(({ data }) => emitSuccessEvent(data))
    .catch((error) => emitErrorEvent(error));
};

export const createUnit = ({ currentCondominium, unit }) =>
  condofyProtected.post(`/v1/condominiums/${currentCondominium.id}/units`, {
    ...unit,
    typeId: unit.type.id
  });

export const editUnit = ({ currentCondominium, unit }) =>
  condofyProtected.put(
    `/v1/condominiums/${currentCondominium.id}/units/${unit.id}`,
    {
      number: unit.number,
      block: unit.block,
      observations: unit.observations,
      typeId: unit?.type?.id || 99
    }
  );

export const fullList = ({ currentCondominium, includeUsers }) =>
  condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/units/list?includeUsers=true`,
    {
      params: {
        includeUsers
      }
    }
  );

export const unitTypeList = () => condofyProtected.get("/v1/unit-types");

export const search = ({
  currentCondominium,
  PageNumber = 1,
  generalSearch = "",
  RecordsPerPage,
  includeUsers = false
}) => {
  return condofyProtected.get(
    `/v1/condominiums/${currentCondominium.id}/units`,
    {
      params: {
        PageNumber,
        GeneralSearch: generalSearch,
        RecordsPerPage,
        includeUsers
      }
    }
  );
};
