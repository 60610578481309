import types from "./actionTypes";
import {
  changePassword,
  changeName,
  changeEmail,
  changePhoneNumber,
  confirmPhoneNumber
} from "@condofy/next/service/user";
import { authenticateWithToken } from "@condofy/next/vendor/@condofy/auth";
import { currentCondominium } from "@condofy/next/service/user";

import { identify } from "@condofy/next/service/segment";
import { fetchNotifications } from "@condofy/next/service/notification";

import { unitMiddlewares } from "./unit";
import { userMiddlewares } from "./user";
import { ticketMiddlewares } from "./ticket";
import { ticketTypeMiddlewares } from "./ticket/type";
import { ticketCommentMiddlewares } from "./ticket/comment";
import { condominiumMiddlewares } from "./condominium";
import { connectionMiddlewares } from "./connection";
import { announcementMiddlewares } from "./announcement";
import { announcementModelMiddlewares } from "./announcementModel";
import { announcementReceiverMiddlewares } from "./announcementReceiver";
import { occurenceMiddlewares } from "./occurence";
import { areaMiddlewares } from "./area";
import { userConnectionMiddlewares } from "./user/connection";

const pending = (dispatch, payload) => {
  dispatch({ type: types.SET_LOADING_INDICATOR, payload });
};

const passwordPending = (dispatch, payload) =>
  dispatch({ type: types.SET_PASSWORD_LOADING_INDICATOR, payload });

const confirmNewPhoneNumber = async (dispatch, payload) => {
  passwordPending(dispatch, true);
  try {
    return confirmPhoneNumber(payload);
  } finally {
    passwordPending(dispatch, false);
  }
};

const loadProfileData = async (dispatch) => {
  pending(dispatch, true);
  try {
    const response = await authenticateWithToken();
    identify(window.analytics, response.data.data, currentCondominium.id);
    const data = response.data.data;
    dispatch({
      type: types.LOAD_PROFILE_DATA_SUCCESS,
      payload: data
    });
    if (data.condominiums.length) {
      if (currentCondominium.id) {
        const condominium = data.condominiums.find(
          ({ condominium }) => condominium.id === currentCondominium.id
        );
        dispatch({
          type: types.SELECT_CURRENT_CONDOMINIUM,
          payload: condominium.condominium
        });
        dispatch({ type: types.SET_ADMIN, payload: condominium });
      } else {
        const condominium = data.condominiums.find(
          (condominium) => !condominium.condominium.isInactive
        );
        dispatch({
          type: types.SELECT_CURRENT_CONDOMINIUM,
          payload: condominium.condominium
        });
        dispatch({
          type: types.SET_ADMIN,
          payload: condominium
        });
      }
    }
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch({ type: types.LOGOUT });
  } finally {
    pending(dispatch, false);
  }
};

const savePassword = async (dispatch, payload) => {
  passwordPending(dispatch, true);
  try {
    return changePassword(payload);
  } finally {
    passwordPending(dispatch, false);
  }
};

const saveName = async (dispatch, payload) => {
  passwordPending(dispatch, true);
  try {
    return changeName({ newName: payload });
  } finally {
    passwordPending(dispatch, false);
  }
};

const saveEmail = async (dispatch, payload) => {
  passwordPending(dispatch, true);
  try {
    return changeEmail({ newEmail: payload });
  } finally {
    passwordPending(dispatch, false);
  }
};

const savePhoneNumber = async (dispatch, payload) => {
  passwordPending(dispatch, true);
  try {
    return changePhoneNumber({ newPhoneNumber: payload });
  } finally {
    passwordPending(dispatch, false);
  }
};

const loadNotification = async (dispatch) => {
  try {
    const response = await fetchNotifications();
    const notificationId = localStorage.getItem("notification");
    if (response.data.data.id !== notificationId) {
      dispatch({
        type: types.LOAD_NOTIFICATION_SUCCESS,
        payload: response.data.data
      });
    }
  } catch (error) {
    // console.log({ ...error })
  }
};

export const applyMiddleware = (dispatch) => (action) => {
  switch (action.type) {
    case types.LOAD_NOTIFICATION:
      return loadNotification(dispatch);

    case types.LOAD_PROFILE_DATA:
      return loadProfileData(dispatch, action.payload);

    case types.CHANGE_PASSWORD:
      return savePassword(dispatch, action.payload);

    case types.CHANGE_NAME:
      return saveName(dispatch, action.payload);

    case types.CHANGE_EMAIL:
      return saveEmail(dispatch, action.payload);

    case types.CHANGE_PHONE_NUMBER:
      return savePhoneNumber(dispatch, action.payload);

    case types.SEND_PHONE_NUMBER_CONFIRMATION_CODE:
      return confirmNewPhoneNumber(dispatch, action.payload);

    default:
  }

  const middlewares = [
    unitMiddlewares,
    userMiddlewares,
    ticketMiddlewares,
    condominiumMiddlewares,
    ticketTypeMiddlewares,
    ticketCommentMiddlewares,
    connectionMiddlewares,
    announcementMiddlewares,
    announcementModelMiddlewares,
    occurenceMiddlewares,
    areaMiddlewares,
    userConnectionMiddlewares,
    announcementReceiverMiddlewares
  ];

  for (const middleware of middlewares) {
    const result = middleware(dispatch, action);
    if (result) {
      return result;
    }
  }

  return dispatch(action);
};
