import React, { useContext } from "react";
import { FlexColumn } from "@condofy/components";
import { MainContext } from "Layout/Main";
import { useUserContext } from "@condofy/next/store/user";
import { CondominiumSelect } from "./CondominiumSelect";
import styled from "styled-components";
import { SidebarNavItem } from "./SidebarNavItem";
import { colors } from "@condofy/components";
import { navItems } from "./navItems";

const Wrapper = styled.aside.withConfig({
  shouldForwardProp: (prop) => !["collapsed"].includes(prop)
})`
  background: ${({ isPremiumPro }) =>
    isPremiumPro ? colors.gray700 : colors.darkBlue700};
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: width 0.7s ease;
  width: 300px;

  * {
    color: ${colors.light100};
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    overflow-x: hidden;
    width: 65px;
  `}
`;

export const Sidebar = () => {
  const { sidebarCollapsed } = useContext(MainContext);
  const {
    state: { currentCondominium, isAdmin, isSuperAdmin }
  } = useUserContext();
  const showPremiumBadge = (route) => {
    const isStarter = !!(currentCondominium.plan.id === "2");
    if (route !== "finance" && isStarter) return true;

    return false;
  };

  const { isPremiumPro } = currentCondominium;

  return (
    <Wrapper collapsed={sidebarCollapsed} isPremiumPro={isPremiumPro}>
      <CondominiumSelect collapsed={sidebarCollapsed}></CondominiumSelect>
      <FlexColumn>
        {navItems.map((item) => (
          <div key={item.id}>
            <span>{item.admin && isAdmin}</span>
            {((item.admin && !item.superAdmin && isAdmin) ||
              ((item.superAdmin || item.admin) && isSuperAdmin) ||
              !item.admin) && (
              <SidebarNavItem
                item={item}
                showPremiumBadge={showPremiumBadge(item.id)}
                collapsed={sidebarCollapsed}
              />
            )}
          </div>
        ))}
      </FlexColumn>
    </Wrapper>
  );
};
