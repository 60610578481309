const dark = {
  dark100: "#99A3B5",
  dark200: "#808CA3",
  dark300: "#667591",
  dark400: "#4D5E7E",
  dark500: "#33476C",
  dark600: "#1A305A",
  dark700: "#001947",
  dark800: "#001130",
  dark900: "#000000"
};

const light = {
  light100: "#FFFFFF",
  light200: "#FAFAFA",
  light300: "#F3F5F8",
  light400: "#E5EBF2",
  light500: "#D7DFE9",
  light600: "#C1C7D0"
};

const darkBlue = {
  darkBlue100: "#99A5CC",
  darkBlue200: "#808EBF",
  darkBlue300: "#6677B3",
  darkBlue400: "#4D61A6",
  darkBlue500: "#334A99",
  darkBlue600: "#1A348D",
  darkBlue700: "#001D80"
};

const brandBlue = {
  brandBlue50: "#DEEBFF",
  brandBlue75: "#B0CDFA",
  brandBlue100: "#99BBF7",
  brandBlue200: "#80A9F5",
  brandBlue300: "#6798F4",
  brandBlue400: "#4D87F2",
  brandBlue500: "#3476F0",
  brandBlue600: "#1A65EE",
  brandBlue700: "#0154EC",
  brandBlue800: "#0052cc"
};

const lightBlue = {
  lightBlue100: "#BFF0FD",
  lightBlue200: "#AFECFD",
  lightBlue300: "#A0E8FD",
  lightBlue400: "#90E4FC",
  lightBlue500: "#80E1FC",
  lightBlue600: "#70DDFB",
  lightBlue700: "#60D9FB"
};

const red = {
  red100: "#FFB1AC",
  red200: "#FF9D97",
  red300: "#FF8983",
  red400: "#FF766E",
  red500: "#FF6259",
  red600: "#FF4F45",
  red700: "#FF3B30"
};

const green = {
  green100: "#AEE9BD",
  green200: "#99E3AC",
  green300: "#85DD9B",
  green400: "#71D88B",
  green500: "#5DD27A",
  green600: "#48CD6A",
  green700: "#34C759"
};

const orange = {
  orange100: "#FAC1A1",
  orange200: "#F8B289",
  orange300: "#F7A371",
  orange400: "#F6935A",
  orange500: "#F58442",
  orange600: "#F3742B",
  orange700: "#F26513",
  orangeTopNavBarBtn: "#f5a442",
  orangeTopNavBarBtnHover: "#e5943a",
  orangeTopNavBarBtnDisabled: "#f5c88f"
};

const yellow = {
  yellow100: "#FFD699",
  yellow200: "#FFCC80",
  yellow300: "#FFC266",
  yellow400: "#FFB84D",
  yellow450: "#FFAB00",
  yellow500: "#FFAD33",
  yellow600: "#FFA31A",
  yellow700: "#FF9900"
};

const gray = {
  gray700: "#404040"
};

const primary = {
  primaryDarker: darkBlue.darkBlue700,
  primary: brandBlue.brandBlue700,
  primaryLighter: brandBlue.brandBlue100
};

const secondary = {
  secondaryDarker: orange.orange700,
  secondary: orange.orange600,
  secondaryLighter: orange.orange300
};

const success = {
  success100: green.green100,
  success200: green.green200,
  success300: green.green300,
  success400: green.green400,
  success500: green.green500,
  success600: green.green600,
  success700: green.green700
};

const warning = {
  warning100: yellow.yellow100,
  warning200: yellow.yellow200,
  warning300: yellow.yellow300,
  warning400: yellow.yellow400,
  warning500: yellow.yellow500,
  warning600: yellow.yellow600,
  warning700: yellow.yellow700
};

const danger = {
  danger100: red.red100,
  danger200: red.red200,
  danger300: red.red300,
  danger400: red.red400,
  danger500: red.red500,
  danger600: red.red600,
  danger700: red.red700
};

const text = {
  textLight: dark.dark500,
  text: dark.dark800
};

export const colors = {
  ...primary,
  ...secondary,
  ...dark,
  ...light,
  ...success,
  ...warning,
  ...danger,
  ...brandBlue,
  ...lightBlue,
  ...darkBlue,
  ...text,
  ...yellow,
  ...red,
  ...green,
  ...orange,
  ...gray
};

export const palettes = {
  primary,
  secondary,
  dark,
  light,
  darkBlue,
  brandBlue,
  lightBlue,
  red,
  orange,
  yellow,
  green,
  success,
  warning,
  danger
};

export const gradients = {
  primary: "linear-gradient(45deg, #002DDF 0%, #017EFA 100%)"
};
