import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as modalNames from "Const";
import { toggleModal } from "Actions/modals";

import ProcessUnitModal from "Components/admin/modals/ProcessUnitModal";
import { DetailsUnitModal } from "Components/admin/modals/DetailsUnitModal";
import DeleteUnitModal from "Components/admin/modals/DeleteUnitModal";
import DeleteUserConnection from "Components/admin/modals/DeleteUserConnection";
import ManagersModal from "Components/admin/modals/ManagersModal";
import {
  OccurrenceAddModal,
  OccurrenceUpdateModal
} from "~/modules/occurrence";
import { AnnouncementMessageModal } from "~/modules/announcement";
// import { InsuranceDialog } from "./finance";

const modals = {
  [modalNames.PROCESS_UNIT_MODAL]: ProcessUnitModal,
  // [modalNames.INSURANCE_MODAL]: InsuranceDialog,
  [modalNames.DETAILS_UNIT_MODAL]: DetailsUnitModal,
  [modalNames.DELETE_UNIT_MODAL]: DeleteUnitModal,
  [modalNames.DELETE_CONNECTION_MODAL]: DeleteUserConnection,
  [modalNames.ASSIGN_MANAGER_MODAL]: ManagersModal,
  [modalNames.ADD_OCCURRENCE_MODAL]: OccurrenceAddModal,
  [modalNames.UPDATE_OCCURRENCE_MODAL]: OccurrenceUpdateModal,
  [modalNames.ADD_ANNOUNCEMENT_MESSAGE_MODAL]: AnnouncementMessageModal
};

const ModalsManager = () => {
  const { isModalOpen, modalOptions: options } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();
  if (!isModalOpen || !modals[options.type]) return null;
  const Modal = modals[options.type];

  return (
    <Modal
      options={options}
      close={() => dispatch(toggleModal(options.type, false))}
    />
  );
};

export default ModalsManager;
