import types from "./actionTypes";
import { identify } from "@condofy/next/service/segment";
import { logoutWithInvalidToken } from "@condofy/next/service/instance";
import { combineReducers } from "./combineReducers";
import { group } from "@condofy/next/service/segment";
import { condominium, condominiumReducer } from "./condominium";
import { unit, unitReducer } from "./unit";
import { user, userReducer } from "./user";
import { ticket, ticketReducer } from "./ticket";
import { ticketType, ticketTypeReducer } from "./ticket/type";
import { ticketComment, ticketCommentReducer } from "./ticket/comment";
import { connection, connectionReducer } from "./connection";
import { announcement, announcementReducer } from "./announcement";
import { occurence, occurenceReducer } from "./occurence";
import { area, areaReducer } from "./area";
import { userConnection, userConnectionReducer } from "./user/connection";
import {
  announcementModel,
  announcementModelReducer
} from "./announcementModel";
import {
  announcementReceiver,
  announcementReceiverReducer
} from "./announcementReceiver";

const profile = {
  pending: false,
  id: false,
  socialSecurityNumber: "",
  name: "",
  email: "",
  phoneNumber: "",
  isLocked: false,
  isAdmin: false,
  isSuperAdmin: false
};

const temporaryProfileData = {
  name: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  socialSecurityNumber: "",
  smsModalOpen: false,
  emailModalOpen: false
};

const appState = {
  currentCondominium: {
    id: false,
    name: "Carregando..."
  },
  condominiumList: [],
  accountList: [],
  notification: ""
};

export const initialState = {
  unit,
  user,
  ticket,
  ticketType,
  ticketComment,
  condominium,
  announcement,
  announcementModel,
  announcementReceiver,
  occurence,
  area,
  userConnection,

  temporaryProfileData,
  connection,
  ...profile,
  ...appState
};

const determineBuildingRole = (currentCondominium) => {
  try {
    return currentCondominium.roles.reduce(
      (accumulator, role) => accumulator || role.id === "2",
      false
    );
  } catch (error) {
    return false;
  }
};

const bigSaladReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT:
      logoutWithInvalidToken();
      return state;

    case types.CLOSE_NOTIFICATION:
      localStorage.setItem("notification", action.payload);
      return {
        ...state,
        notification: false
      };

    case types.LOAD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload
      };

    case types.UPDATE_TEMPORARY_NAME:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          name: action.payload
        }
      };

    case types.UPDATE_TEMPORARY_EMAIL:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          email: action.payload
        }
      };

    case types.UPDATE_TEMPORARY_PHONE_NUMBER:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          phoneNumber: action.payload
        }
      };

    case types.UPDATE_TEMPORARY_SOCIAL_SECURITY_NUMBER:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          socialSecurityNumber: action.payload
        }
      };

    case types.SET_LOADING_INDICATOR:
      return { ...state, pending: action.payload };

    case types.SELECT_CURRENT_CONDOMINIUM:
      // TODO change this to use from store
      localStorage.setItem("currentCondominium", action.payload.id);
      group(window.analytics, action.payload);
      identify(
        window.analytics,
        {
          ...state,
          Plano: action.payload.plan && action.payload.plan.name
        },
        action.payload.id
      );
      return { ...state, currentCondominium: action.payload };

    case types.SET_ADMIN:
      return {
        ...state,
        isAdmin: determineBuildingRole(action.payload)
      };

    case types.LOAD_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          ...action.payload
        },
        condominiumList: action.payload.condominiums.map(
          ({ condominium, roles }) => ({ ...condominium, roles })
        )
      };

    case types.LOAD_PROFILE_DATA_ERROR:
      return { ...state, error: action.payload };

    case types.SET_SMS_MODAL_VISIBLE:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          smsModalOpen: action.payload
        }
      };

    case types.SET_EMAIL_MODAL_VISIBLE:
      return {
        ...state,
        temporaryProfileData: {
          ...state.temporaryProfileData,
          emailModalOpen: action.payload
        }
      };

    default:
      return state;
  }
};

export const reducer = combineReducers({
  bigSaladReducer,
  unitReducer,
  userReducer,
  ticketReducer,
  ticketTypeReducer,
  ticketCommentReducer,
  condominiumReducer,
  connectionReducer,
  announcementReducer,
  announcementModelReducer,
  announcementReceiverReducer,
  occurenceReducer,
  areaReducer,
  userConnectionReducer
});
