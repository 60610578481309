import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { handleError } from "~/next/hooks";
import { initBemteviCheckoutNew } from "~/next/service/unit";

export const InsuranceDialog = () => {
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(true);

  const handleInsuranceFlow = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await initBemteviCheckoutNew();
    } catch (error) {
      handleError(enqueueSnackbar)(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    console.log("handleClose", { event, reason });
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      slots={{
        backdrop: () => (
          <Backdrop open={isOpen} onClick={handleClose} invisible={true} />
        )
      }}
      PaperProps={{
        sx: {
          position: "fixed",
          bottom: 30,
          left: 270,
          m: 0,
          borderRadius: 3,
          boxShadow: 3,
          padding: 2,
          maxWidth: 280
        }
      }}
    >
      <DialogContent sx={{ position: "relative", p: 2 }}>
        {/* Close Button */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "#888", // Dark gray background
            width: 24,
            height: 24,
            borderRadius: "50%", // Circular button
            color: "#FFF", // White 'X' icon
            "&:hover": {
              backgroundColor: "#777" // Slightly darker gray on hover
            }
          }}
          size="small"
        >
          <CloseIcon sx={{ fontSize: 16 }} />
        </IconButton>

        <Stack spacing={1}>
          {/* "Novo" Badge */}
          <Box
            sx={{
              alignSelf: "flex-start", // Ensures proper positioning
              backgroundColor: "#FF9F71",
              color: "white",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "4px 8px",
              display: "inline-block" // Forces it to take its own line
            }}
          >
            Novo
          </Box>

          {/* Title */}
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 2 }}>
            Conheça o Seguro Residencial da Condofy!
          </Typography>

          {/* "Ver mais" Button */}
          <Button
            variant="contained"
            sx={{
              alignSelf: "flex-start", // Ensures proper positioning
              backgroundColor: "white",
              color: "black",
              border: "1px solid #ddd",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#f5f5f5" }
            }}
            endIcon={<span style={{ fontSize: "16px" }}>→</span>}
            onClick={handleInsuranceFlow}
          >
            Ver mais
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
