import React, { useState } from "react";
import { theme, colors } from "../../styles";
import { Tabs } from "./Tabs";
import { Tab } from "./Tab";
import styled from "styled-components";

const StyledTabsPanel = styled(Tabs)`
  &.MuiTabs-root {
    background: ${colors.light100};
    border-bottom: solid 1px ${colors.light400};
    margin-top: -${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    padding: ${theme.spacing(3)};
  }
`;

const TabPanel = ({ id, children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={id}
      aria-labelledby={id}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const TabsPanel = ({
  indicatorColor,
  afterTabs,
  onRightEdge,
  children,
  ...other
}) => {
  const uniqueId = () => Math.random().toString(36).substring(7);
  const [value, setValue] = React.useState(0);
  const [id] = useState(uniqueId("prefix-"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return {
        label: child.props.label,
        children: child.props.children
      };
    }
  });

  return (
    <React.Fragment>
      <StyledTabsPanel
        value={value}
        indicatorColor={indicatorColor || "primary"}
        onChange={handleChange}
        {...other}
      >
        {tabs.map(({ label }, index) => (
          <Tab key={index} id={`${id}-tab-${index}`} label={label} />
        ))}
        {onRightEdge && <div style={{ marginLeft: "auto" }}>{onRightEdge}</div>}
      </StyledTabsPanel>
      {afterTabs}
      {tabs.map(({ children }, index) => (
        <TabPanel
          value={value}
          index={index}
          key={index}
          id={`${id}-tabpanel-${index}`}
        >
          {children}
        </TabPanel>
      ))}
    </React.Fragment>
  );
};
