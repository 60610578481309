import React, { useState } from "react";
import { useSnackbar, handleError } from "@condofy/next/hooks/useSnackbar";
import { Modal, ActionRow, ModalContent } from "@condofy/next/components";

import { useUserContext } from "@condofy/next/store/user";
import { CondominiumForm } from "./CondominiumForm";
import { track } from "@condofy/next/service/segment";

export const CondominiumEditModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const {
    state: {
      condominium: { editModalOpen, selectedCondominium }
    },
    actions: {
      closeEditCondominiumModal: close,
      updateSelectedCondominium,
      editCondominium,
      listCondominium
    }
  } = useUserContext();

  const handleSuccess = () => {
    track(window.analytics, "Condominium Edited", selectedCondominium);
    enqueueSnackbar(`Condomínio alterado com sucesso`, { variant: "success" });
    close();
    listCondominium();
  };

  const reset = () => setLoading(false);

  const handleSave = () => {
    setLoading(true);

    editCondominium({
      ...selectedCondominium,
      address: {
        ...selectedCondominium.address,
        zipCode: selectedCondominium.address.zipCode.replace(/\D/g, "")
      }
    })
      .then(handleSuccess)
      .catch(handleError(enqueueSnackbar))
      .finally(reset);
  };

  return (
    <Modal
      open={editModalOpen}
      onClose={close}
      title={"Editar condomínio"}
      footer={
        <ActionRow onCancel={close} onSubmit={handleSave} loading={loading} />
      }
    >
      <ModalContent>
        {selectedCondominium && (
          <CondominiumForm
            condominium={selectedCondominium}
            onChange={updateSelectedCondominium}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
