import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import styled from "styled-components";
import { handleError } from "../hooks";

const key = process.env.REACT_APP_IMAGE_UPLOAD_API_PUBLIC_KEY;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const maxSizeKilobytes = 200 * 1024;
// const maxSizePx = 200

const FILE_ERRORS = {
  "file-too-big": `File is too big (max size is ${maxSizeKilobytes} KB)`,
  "file-invalid-type": "File type is invalid (only images are allowed)"
  //   'file-invalid-dimensions': `File dimensions are too big (max height/width is ${maxSizePx}px)`
};

export const FileUploadButton = ({ onFileUploaded, editMode }) => {
  const [loading, setLoading] = useState(false);
  const [fileIsUploaded, setFileIsUploaded] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmission = (event) => {
    const [file] = event.target.files;

    if (!file) {
      return;
    }

    if (file.size > maxSizeKilobytes) {
      handleError(enqueueSnackbar)(FILE_ERRORS["file-too-big"]);
      return;
    }

    if (!file.type.startsWith("image/")) {
      handleError(enqueueSnackbar)(FILE_ERRORS["file-invalid-type"]);
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("image", event.target.files[0]);

    const baseURL = `https://api.imgbb.com/1/upload?key=${key}`;

    fetch(baseURL, {
      method: "POST",
      body: data
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
        onFileUploaded(result.data.url);
        setFileIsUploaded(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };

  const noFileUploadedLabel = editMode ? "Alterar logo" : "Inserir logo";

  const buttonLabel = loading
    ? "Loading..."
    : fileIsUploaded
      ? "Inserir outro logo"
      : noFileUploadedLabel;

  return (
    <>
      {fileIsUploaded && (
        <CheckIcon style={{ flexShrink: 1, marginLeft: 1, color: "green" }} />
      )}
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        disabled={loading}
        onChange={handleSubmission}
        startIcon={<CloudUploadIcon />}
      >
        {buttonLabel}
        <VisuallyHiddenInput type="file" />
      </Button>
    </>
  );
};
