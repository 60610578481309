import React from 'react';

import {
  Alert,
  Card,
  CardContent,
  DateText,
  Flex,
  TabsPanel,
  TabsPanelItem
} from '@condofy/components';
import { useUserContext } from '@condofy/next/store/user';
import {
  BankAccountSelector,
  FinancialStatement
} from '../../next/containers/finance';

const StatementAlert = () => (
  <Alert severity='warning' variant='outlined'>
    Os saldos e lançamentos apresentados abaixo são baseados nas informações
    disponíveis e atualizadas até <DateText date={new Date()} /> às{' '}
    <DateText date={new Date()} format='time' />. As informações poderão sofrer
    alterações em função de novos lançamentos.
  </Alert>
)

export const FinancialStatementTab = () => {

  const {state: {currentCondominium:{plan: {id: condominiumPlanId}}}} = useUserContext();

  return (
    <Flex direction='column' spacingY={3}>
      <StatementAlert />
      <Card>
        <CardContent>
          <Flex direction='column' spacingY={3} width='100%'>
            <BankAccountSelector hideIfOneOption />
            <div>
              <TabsPanel>
                <TabsPanelItem label='Extrato Detalhado'>
                  <FinancialStatement type='toDetailed' />
                </TabsPanelItem>
                {condominiumPlanId !== '2' && (
                  <TabsPanelItem label='A Pagar'>
                  <FinancialStatement type='toPay' />
                </TabsPanelItem>)}
                {(condominiumPlanId !== '2') && (
                  <TabsPanelItem label='A Receber'>
                    <FinancialStatement type='toReceive' />
                  </TabsPanelItem>
                )}
              </TabsPanel>
            </div>
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  )
}
