import {
  AnnouncementCreateModal,
  AnnouncementEditModal,
  AnnouncementeModelCreateModal,
  AnnouncementeModelEditModal,
  BillingDetailModal,
  CondominiumActiveModal,
  CondominiumCreateModal,
  CondominiumDeleteModal,
  CondominiumEditModal,
  CondominiumInactiveModal,
  CreateAreaModal,
  CreateConnectionModal,
  EditAreaModal,
  EmailConfirmationModal,
  SmsCodeModal,
  TicketCreateModal,
  TicketEditModal,
  UserConnectionEditModal,
  UserCreateModal,
  UserDeleteModal,
  UserDetailsModal,
  UserEditModal
} from "@condofy/next/containers/";
import { AddUserConnection } from "Components/admin/modals/AddUserConnection";
import React from "react";
import { BillingDeclarationModal } from "./billing/BillingDeclarationModal";
import { MobileAppModal } from "./mobile/MobileAppModal";

export const ModalContainer = () => (
  <>
    <BillingDetailModal />
    <BillingDeclarationModal />

    <SmsCodeModal />
    <EmailConfirmationModal />
    <CreateConnectionModal />

    <AddUserConnection />

    <CondominiumCreateModal />
    <CondominiumEditModal />
    <CondominiumDeleteModal />
    <CondominiumInactiveModal />
    <CondominiumActiveModal />

    <UserCreateModal />
    <UserEditModal />
    <UserDetailsModal />
    <UserDeleteModal />

    <TicketCreateModal />
    <TicketEditModal />

    <AnnouncementCreateModal />
    <AnnouncementEditModal />

    <AnnouncementeModelCreateModal />
    <AnnouncementeModelEditModal />

    <UserConnectionEditModal />

    <CreateAreaModal />
    <EditAreaModal />
    <MobileAppModal />
  </>
);
